.tickets-category-name {
  font-family: 'Bilo';
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 44px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #23232e;
  margin-bottom: 115px;
}
