@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

$font-family-sans-serif: 'Poppins', system-ui, -apple-system, 'Segoe UI', Poppins, 'Helvetica Neue', Arial, 'Noto Sans',
  'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-heading: 'Bilo', sans-serif;
/***
Colors
***/
$fragmintGreen: #01e6ad;
$teal: #57f0db;
$primary: $teal;
$white: #fff;
$gray-100: rgba(#303234, 0.9);
$gray-200: rgba(#303234, 0.7);
$gray-300: rgba(#303234, 0.6);
$gray-400: rgba(#303234, 0.5);
$gray-500: rgba(#303234, 0.4);
$gray-600: rgba(#303234, 0.25);
$gray-700: rgba(#303234, 0.15);
$gray-800: rgba(#303234, 0.1);
$gray-900: #16191d;

$black: #0f1012;
$text-muted: #7f828d;
/***
Font sizes
***/
$font-xxs: 0.5rem;
$font-xs: 0.7rem;
$font-sm: 0.875rem;
$font-base: 1rem;
$font-lg: 1.125rem;
$font-xl: 1.25rem;
$font-2xl: 1.5rem;
$font-3xl: 1.875rem;
$font-4xl: 2.25rem;
$font-5xl: 3rem;
$font-6xl: 3.75rem;
$font-7xl: 4.5rem;
/***
Margin & Padding spacings
***/
$sp-xxs: 0.25rem;
$sp-xs: 0.375rem;
$sp-sm: 0.5rem;
$sp-base: 0.75rem;
$sp-lg: 1rem;
$sp-xl: 1.5rem;
$sp-2xl: 2rem;
$sp-3xl: 2.5rem;
$sp-4xl: 4.5rem;
$sp-5xl: 6.5rem;
$sp-6xl: 9rem;
$sp-7xl: 12rem;
/***
Rounded corners
***/
$rounded-none: 0;
$rounded-sm: 0.25rem;
$rounded-md: 0.375rem;
$rounded-lg: 0.5rem;
$rounded-xl: 0.75rem;
$rounded-2xl: 1rem;
$rounded-3xl: 1.5rem;
$rounded-full: 9999px;
/***
pagination
***/
$pagination-color: $white;
$pagination-bg: $gray-700;
$pagination-border-width: 0;
$pagination-border-radius: $rounded-sm;
$pagination-margin-start: -$pagination-border-width;
$pagination-border-color: $gray-300;
$pagination-focus-color: $primary;
$pagination-focus-bg: $gray-700;
$pagination-focus-outline: 0;
$pagination-hover-color: $primary;
$pagination-hover-bg: $gray-600;
$pagination-hover-border-color: $gray-300;
