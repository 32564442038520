.about-nft-tickets-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 88px;
  @media (max-width: 1230px) {
    padding-left: 1em;
    padding-right: 1em;
  }
  .about-nft-tickets-container {
    max-width: 1200px;
    h3 {
      font-family: 'Bilo';
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      line-height: 44px;
      letter-spacing: -0.02em;
      text-transform: capitalize;
      color: #23232e;
      margin-bottom: 51px;
    }

    img {
      height: 100px;
      width: 100px;
      margin-bottom: 24px;
    }

    .tickets-descriptions {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #49494f;
      max-width: 800px;
      margin-bottom: 72px;
    }
    .about-cards-container {
      display: flex;
      flex-wrap: wrap;
    }

    a {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 32px;
      letter-spacing: 0.01em;
      color: #ffffff;
      text-decoration: none;
      width: 165px;
      background: #863ee2;
      border-radius: 56px;
      padding: 8px 40px;
      gap: 10px;
    }
  }
}
