.navbar {
  width: 100%;
  .container {
    min-width: 300px;
    max-width: 1260px;
    height: 64px;
    padding: 1em 2em;
    .brand-container {
      display: flex;
      align-items: center;
      max-width: 234px;
      max-height: 32px;
      .divider {
        width: 1px;
        height: 25px;
        background-color: #d9d9d9;
        margin: 0 16px;
      }
      > h6 {
        font-size: 1em;
        color: #49494F;
        margin: 0;
      }
      .navbar-brand {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .navbar-toggler {
      border: none;
      outline: none;
      box-shadow: none;
    }
    .navbar-toggler-icon {
      font-size: larger;
    }
  }
}

.plentix-icon {
  margin-right: 5.5px;
}

.navbar-nav {
  display: flex;
  align-items: center;
  @media (max-width: 991px) {
    position: absolute;
    z-index: 2;
    align-items: flex-start;
  }
  .help-dropdown {
    font-size: 1em;
    font-weight: 500;
    position: relative;
    margin-right: 45px;
    a {
      color: #23232e;
    }
    ::after {
      font-size: 10px;
      color: #f347ba;
      margin-left: 0.75em;
    }
  }
  .additional-info-link {
    margin-right: 0.5em;
    font-size: 1em;
    font-weight: 500;
    color: #23232e;
  }
}

.offcanvas {
  width: 100% !important;
  .offcanvas-header {
    .offcanvas-title {
      display: flex;
      align-items: center;
    }
    div {
      display: block;
      padding-top: 0.25em;
    }
    .btn-close {
      margin-right: 1em;
      font-size: larger;
      box-shadow: none;
      border: none;
      outline: none;
    }
  }
  .connect-button-container {
    margin-top: 0.5em;
  }
  .dropdown-menu {
    margin-top: 0 !important;
    padding-top: 0;
    border: none;
  }
  .dropdown-divider {
    display: none;
  }
}
