@import '_vars';
@import '../node_modules/bootstrap/scss/bootstrap.scss';

body {
  // background-color: #16191d;
  color: #fff;
}

.hidden {
  display: none !important;
}

.container-fluid.bordered {
  border: 1px solid $gray-100;
  border-left: 0;
  border-right: 0;
}

.network-container-specification {
  width: 317px;
  display: flex;
  margin-bottom: 22px;
  overflow: hidden;

  div {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Space Grotesk';
    color: #8d8f93;
  }

  .network-button {
    padding-bottom: 16px;
    border-bottom: 1px solid #494d5b;
    flex-basis: 33.3333333%;
    display: flex;
    justify-content: center;
    color: #67686a;
    transition: color 0.4s;
    font-family: 'Space Grotesk';
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.2px;
    border-radius: 0 !important;
    -webkit-appearance: none;
    &:hover {
      color: #b8b8b8;
    }
  }

  .onClick-button {
    color: #ffffff;
    border-bottom: 1px solid #78ffde;
    -webkit-appearance: none;
    border-radius: 0 !important;
  }
}
