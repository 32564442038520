.home-header-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 86px 0 70px 0;
  margin-bottom: 102px;
  .home-header-content {
    display: flex;
    max-width: 1200px;
    justify-content: space-between;
    @media (max-width: 1230px) {
      display: block;
      padding: 0 1em;
    }
    img {
      max-width: 588px;
      object-fit: cover;
      height: auto;
      width: 100%;
      @media (max-width: 1230px) {
        margin-top: 3em;
      }
    }
    .home-header-content-name {
      max-width: 612px;
      h1 {
        font-family: 'Bilo';
        font-style: normal;
        font-weight: 700;
        font-size: 68px;
        line-height: 64px;
        text-transform: capitalize;
        color: #ffffff;
        margin: 25px 0 16px 0;
      }
      p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 61px;
        color: #ffffff;
      }
      a {
        background: #ff3cbf;
        border-radius: 44px;
        width: 190px;
        height: 48px;
        text-decoration: none;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 0.01em;
        color: #ffffff;
        padding: 8px 40px;
      }
    }
  }
}
