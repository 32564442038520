.wrap-ticket-content {
  width: 250px;
  margin: 0 48px 22px 0;
  .name {
    font-family: 'Bilo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: capitalize;
    color: #23232e;
    margin-bottom: 14px;
  }
  .descriptions {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #49494f;
    opacity: 0.8;
  }
}
