.event-card-link {
  text-decoration: none;
  margin-right: 16px;

  .event-card-image-container {
    position: relative;
    max-width: 380px;
    height: 220px;
    .event-card-img {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      border-radius: 4px;
    }
    .date-over-image {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 61px;
      height: 59px;
      right: 25px;
      top: 0px;
      background: #23232e;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
      border-radius: 2px;
      padding: 14px;
      .date-over-image-center {
        width: 100%;
        height: 100%;
        p:first-of-type {
          font-size: 10px;
        }
        p {
          font-size: 12.4612px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          text-align: center;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          padding: 0;
          margin: 0;
          color: #fefcfc;
        }
      }
    }
  }

  .event-card-description {
    padding-left: 8px;
    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: rgba(137, 143, 150, 0.7);
    }
    .name {
      font-family: 'Bilo';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      text-transform: capitalize;
      color: #282835;
    }
    .date {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #49494f;
    }
  }
}
