.link-section-name {
  line-height: 2.25em;
  margin-right: 4em;
  @media (max-width: 768px) {
    padding: 0;
  }
  span {
    margin-bottom: 1em;
    padding: 0 0.5em;
    font-weight: 500;
    color: #23232e;
    @media (max-width: 768px) {
      padding: 0;
    }
  }
  .link-container {
    display: flex;
    a {
      display: flex;
      text-decoration: none;
      &:hover {
        background-color: #f6f5fa;
        border-radius: 80px;
        transition: all 0.3s;
      }
      img {
        margin: 0 0.5em;
        @media (max-width: 768px) {
          margin-left: 0;
        }
      }
      h6 {
        font-size: 13px;
        color: #595968;
        line-height: 1.5em;
        padding: 0.25em 0.5em;
        margin-bottom: 0.25em;
        white-space: nowrap;
        @media (max-width: 768px) {
          padding: 0;
        }
      }
    }
  }
}
