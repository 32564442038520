.connect-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 175px;
  height: 40px;
  border-radius: 56px;
  background-color: #863ee2;
  color: white;
  border: none;
  transition: all 0.4s;
  &:hover {
    color: #f1dddd;
    span {
      transition: font-size 0.4s;
    }
  }
  span {
    font-size: 1em;
    font-weight: 500;
  }
}

.connect-button-container-disconnect {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 254px;
  height: 40px;
  background: #f6f5fa;
  border-radius: 52px;
  padding: 0px 17px;
  border: transparent;
  cursor: default !important;
  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }
  .address {
    color: #8f8e99;
  }
  .description {
    color: #23232e;
    cursor: pointer;

    transition: all 0.4s;
    &:hover {
      color: rgb(99, 87, 87);
    }
  }
}
