.about-section {
  max-width: 305px;
  margin-right: 4em;
  img {
    margin-bottom: 1em;
  }
  p {
    color: #6e6d76;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
  }
  h6 {
    font-family: 'Inter';
    font-weight: 400;
    margin-top: 1.5em;
    color: #6e6d76;
    font-size: 12px;
  }
}
