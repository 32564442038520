.event-name {
  font-family: 'Bilo';
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  line-height: 66px;
  letter-spacing: -0.03em;
  text-transform: capitalize;
  color: #23232e;
  margin-bottom: 26px;
  @media (max-width: 410px) {
    font-size: 54px;
  }
}

.userProfile {
  display: flex;
  align-items: center;
  margin-bottom: 1.75em;
  span {
    font-family: 'Montserrat';
    font-weight: 700;
    margin-left: 0.25em;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: #23232e;
  }
}

.event-description {
  margin-bottom: 1.75em;
  .text-header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #49494f;
    margin-bottom: 1.75em;
  }
}

.learn-more-button {
  background: #ff3cbf;
  border-radius: 44px;
  text-decoration: none;
  padding: 5px 35px;
  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: white;
  }
}

.additional-content {
  width: fit-content;
  margin-bottom: 4em;
  margin-top: 1.75em;
  h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14.4415px;
    line-height: 135%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #000000;
    opacity: 0.5;
  }
  p {
    width: 131px;
    height: 22px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16.2466px;
    line-height: 135%;
    color: #000000;
    width: fit-content;
  }
  .event-details-date {
    width: 131px;
    height: 22px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16.2466px;
    line-height: 135%;
    color: #000000;
    width: fit-content;
  }
}

hr {
  height: 1px;
  background: #d9d9d9;
  margin-bottom: 5.5em !important;
}
