.home-wrap-second {
  margin: 100px 200px;

  @media screen and (max-width: 1024px) {
    margin: 100px 50px;
  }
}

hr {
  height: 2px;
  color: #d9d9d9;
}
