.tickets-category {
  display: flex;
  align-items: center;
  height: fit-content;
  margin-bottom: 4.5em;
  @media (max-width: 768px) {
    display: block;
  }
  .section {
    margin: 0 2.75em;
    h1 {
      font-family: 'Montserrat';
      font-weight: 700;
      line-height: 140%;
      font-size: 27px;
      letter-spacing: 0.01em;
      color: #161616;
      margin-bottom: 0;
    }
    p {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 22px;
      line-height: 140%;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      color: #161616;
      opacity: 0.5;
      margin: 0;
    }
  }
  .categories-number-prices {
    display: flex;
    align-items: center;
    height: fit-content;
    @media (max-width: 768px) {
      margin-bottom: 1.5em;
    }

    div:first-of-type {
      margin-left: 0;
    }
  }
  .categories-sold-total {
    display: flex;
    align-items: center;
    height: fit-content;
    @media (max-width: 768px) {
      div:first-of-type {
        margin-left: 0;
      }
    }
  }
  .vl {
    border-left: 2px solid;
    color: #d9d9d9;
    height: 31px;
  }
}
