.events-gallery-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  @media (max-width: 1230px) {
    padding: 0 1em;
  }
  .events-gallery-container {
    max-width: 1200px;
    width: 100%;
    h2 {
      font-family: 'Bilo';
      font-style: normal;
      font-weight: 700;
      font-size: 56px;
      line-height: 56px;
      letter-spacing: -0.03em;
      text-transform: capitalize;
      color: #23232e;
      margin-bottom: 40px;
    }
    .events-gallery {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
