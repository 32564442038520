.landing-page-wrap {
  display: flex;
  justify-content: center;
  padding: 0 1em;
  .landing-page-container {
    width: 100%;
    max-width: 1260px;
    .landing-page-image {
      display: flex;
      justify-content: center;
      width: 100%;
      img {
        max-width: 1260px;
        margin-bottom: 4.5em;
        margin-top: 0.75em;
        border-radius: 14px;
      }
    }
    .landing-page-content-wrapper {
      display: flex;
      justify-content: center;
      .landing-page-content {
        width: 100%;
        max-width: 1200px;
      }
    }
  }
}
