.category-card-wrapper {
  display: flex;
  border: 1px solid #d6d3df;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 2em;
  padding: 2em;
  @media (max-width: 1060px) {
    display: block;
  }
  .category-image-container {
    display: flex;
    flex-direction: column;
    margin-right: 3.75em;
    @media (max-width: 1060px) {
      flex-direction: row;
      justify-content: center;
      margin: 0 0 1em 0;
    }
    img {
      width: 345px;
      max-height: 220px;
      border-radius: 8px;
    }
  }
  .category-card-information {
    width: 100%;
    @media (max-width: 1060px) {
      text-align: center;
    }
    .category-perks {
      margin-bottom: 34px;
      color: #49494f;
      font-size: 18px;
      line-height: 28px;
    }
    .category-name {
      font-weight: 700;
      font-size: 32px;
      line-height: 36px;
      letter-spacing: 0.01em;
      color: #161616;
      margin-bottom: 16px;
    }
    .category-description {
      font-size: 18px;
      line-height: 28px;
      color: #49494f;
      opacity: 0.7;
      margin-bottom: 10px;
    }
    .category-card-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      @media (max-width: 768px) {
        display: block;
      }
      .category-price-summary {
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          justify-content: center;
        }
        .category-price {
          margin-right: 2em;
          @media (max-width: 768px) {
            margin-bottom: 1.5em;
          }
          .ticket-price {
            font-family: 'Inter';
            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
            color: #23232e;
            margin: 0;
          }
        }
        .category-sold {
          @media (max-width: 768px) {
            margin-bottom: 1.5em;
          }
          .category-sold-display {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 26px;
            width: 58px;
            background: #fc70ff;
            border-radius: 5.5px;
            span {
              font-family: 'Inter';
              font-weight: 700;
              font-size: 16px;
              line-height: 19px;
              color: #ffffff;
            }
          }
        }
        .ticket-price-label {
          color: #23232e;
          margin-bottom: 0.5em;
          opacity: 0.4;
          font-family: 'Inter';
          font-weight: 500;
          font-size: 22px;
          line-height: 120%;
        }
      }
      .button-wrapper {
        @media (max-width: 768px) {
          display: flex;
          width: 100%;
          justify-content: center;
        }
        a,
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ff3cbf;
          border-radius: 44px;
          width: 279.56px;
          height: 63px;
          span {
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: 0.01em;
            color: #ffffff;
          }
        }
      }
    }
    > hr {
      color: #d9d9d9;
      opacity: 0.5;
      margin-bottom: 2.25em !important;
    }
  }
}
