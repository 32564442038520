.footer-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #f1eef5;
  padding-top: 4.5em;
  .footer-container {
    display: flex;
    justify-content: space-between;
    width: 1260px;
    padding: 0 2em;
    @media (max-width: 1024px) {
      display: block;
    }
    .footer-main-section {
      display: flex;
      @media (max-width: 768px) {
        display: block;
      }
    }
  }
}
