.partner-ribbon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: #fff5fb;
  @media (min-width: 768px) {
    height: 56px;
  }
  .message-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1260px;
    .ribbon-message {
      display: flex;
      span {
        margin-right: 0.5em;
        font-size: 14px;
      }
      .ribbon-link {
        display: flex;
        align-items: center;
        a {
          color: #ff3cbf;
          text-decoration: none;
          font-size: 14px;
          font-weight: 500;
        }
        img {
          width: 8px;
          height: 8px;
          margin-left: 0.25em;
        }
      }
    }
    img {
      width: 75px;
      height: 23px;
    }
  }
}
