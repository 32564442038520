.footer-reminder-section {
  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 2em;
  }
  .reminder {
    background-color: #f6f5fa;
    width: 340px;
    height: 289px;
  }
}
